import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { save } from '../../store/user/actions';

const initialState = {
  firstName: '',
  lastName: '',
  genderId: '',
  programDurationId: '',
  employeeStatusId: '',
  totalExperienceId: '',
  presentExperienceId: '',
  countryId: '',
  occupation: '',
  education: '',
  month: '',
  date: '',
  year: '',
};

const ProfileForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currState, setCurrState] = useState(initialState);
  const [formOptions, setFormOptions] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [Years, setYears] = useState([]);
  const [Days, setDays] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [submitErrors, setSubmitErrors] = useState({});

  useEffect(() => {
    setYears(window.$utility.getYears());
    setDays(window.$utility.getDaysList());
    getList();
  }, []);

  const getList = async () => {
    try {
      const result = await window.$http.getWithHeaders(
        'lookup?types=country,gender,employee_status,experience,occupation,education'
      );

      if (result.code === window.$constants.STATUS.OK) {
        return setFormOptions(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCurrState({
      firstName: props?.profileData?.first_name,
      lastName: props?.profileData?.last_name,
      genderId: props?.profileData?.rp_gbl_gender_id,
      programDurationId: props?.profileData?.rp_lookup_program_duration_id,
      employeeStatusId: props?.profileData?.rp_gbl_employee_status_id,
      totalExperienceId: props?.profileData?.rp_total_experience_id,
      presentExperienceId: props?.profileData?.rp_present_experience_id,
      countryId: props?.profileData?.rp_gbl_country_id,
      year:
        props?.profileData.dob !== null ||
        props?.profileData.dob !== 'Invalid date'
          ? moment(props?.profileData?.dob).format('YYYY')
          : '',
      month:
        props?.profileData.dob !== null ||
        props?.profileData.dob !== 'Invalid date'
          ? parseInt(moment(props?.profileData?.dob).format('M') - 1)
          : '',
      date:
        props?.profileData.dob !== null ||
        props?.profileData.dob !== 'Invalid date'
          ? moment(props?.profileData?.dob).format('D')
          : '',
      education: props?.profileData?.rp_gbl_education_id,
      occupation: props?.profileData?.rp_gbl_occupation_id,
    });
  }, [props?.profileData]);

  const handleChange = (e) => {
    setIsSubmit(false);
    const { name, value } = e.target;
    let currentFormErrors = formErrors;
    var day;

    switch (name) {
      case 'genderId':
        if (!value) {
          currentFormErrors[name] = 'Please select gender';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

      case 'employeeStatusId':
        if (!value) {
          currentFormErrors[name] = 'Please select employee status';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

      case 'totalExperienceId':
        if (!value) {
          currentFormErrors[name] = 'Please select total experience';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

      case 'presentExperienceId':
        if (!value) {
          currentFormErrors[name] = 'Please select present experience';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

      case 'countryId':
        if (!value) {
          currentFormErrors[name] = 'Please select country';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

      case 'occupation':
        if (!value) {
          currentFormErrors[name] = 'Please select occupation';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

      case 'education':
        if (!value) {
          currentFormErrors[name] = 'Please select education';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
          return setCurrState({
            ...currState,
            education: value,
          });
        }
        break;

      case 'year':
        if (!value) {
          currentFormErrors[name] = 'Please select year.';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
          while (Days.length) {
            Days.pop();
          }
          day = window.$utility.getLastDay(value, currState.month);
          for (let i = 1; i <= day; i++) {
            Days.push(i);
            setDays((prev) => Days);
          }
        }
        break;

      case 'month':
        if (!value) {
          currentFormErrors[name] = 'Please enter select month.';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
          while (Days.length) {
            Days.pop();
          }
          day = window.$utility.getLastDay(currState.year, value);
          for (let i = 1; i <= day; i++) {
            Days.push(i);
            setDays((prev) => Days);
          }
        }
        break;

      case 'date':
        if (!value) {
          currentFormErrors[name] = 'Please select date.';
        } else {
          delete currentFormErrors[name];
          delete submitErrors[name];
        }
        break;

        case 'programDurationId':
          if (!value) {
            currentFormErrors[name] = 'Please select a program duration';
          } else {
            delete currentFormErrors[name];
            delete submitErrors[name];
          }
          break;

      default:
        currentFormErrors = 'All fields are required';
        break;
    }

    setCurrState({ ...currState, [name]: value });
    setFormErrors(currentFormErrors);
  };

  useEffect(() => {
    if (isSubmit) {
      if (+currState?.occupation === window.$enums.OccupationType.Student) {
        [
          'employeeStatusId',
          'totalExperienceId',
          'presentExperienceId',
        ].forEach((e) => delete submitErrors[e]);
      } else if (
        +currState?.occupation === window.$enums.OccupationType.HouseManager ||
        +currState?.occupation === window.$enums.OccupationType.General
      ) {
        [
          'education',
          'employeeStatusId',
          'totalExperienceId',
          'presentExperienceId',
        ].forEach((e) => delete submitErrors[e]);
      } else if (
        +currState?.occupation === window.$enums.OccupationType.Employee
      ) {
        ['education'].forEach((e) => delete submitErrors[e]);
      }
    }
  }, [currState?.occupation, isSubmit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitErrors({});
    let currSubmitErrors = submitErrors;
    if (!currState?.year || currState?.year === 'Invalid date') {
      setIsSubmit(true);
      currSubmitErrors['year'] = 'year';
    }
    if (
      currState?.month === '' ||
      currState?.month < 0 ||
      isNaN(currState?.month)
    ) {
      setIsSubmit(true);
      currSubmitErrors['month'] = 'month';
    }
    if (!currState?.date || currState?.date === 'Invalid date') {
      setIsSubmit(true);
      currSubmitErrors['date'] = 'date';
    }
    if (!currState?.genderId) {
      setIsSubmit(true);
      currSubmitErrors['genderId'] = 'genderId';
    }
    if (!currState?.countryId) {
      setIsSubmit(true);
      currSubmitErrors['countryId'] = 'countryId';
    }

    if (
      props?.profileData?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.Personal ||
      props?.profileData?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.Clinical
    ) {
      if (!currState?.occupation) {
        setIsSubmit(true);
        currSubmitErrors['occupation'] = 'occupation';
      }
      if (+currState?.occupation === window.$enums.OccupationType.Student) {
        if (!currState.education) {
          setIsSubmit(true);
          currSubmitErrors['education'] = 'education';
        }
      }
    }

    if (
      props?.profileData?.rp_gbl_company_type_id ===
        window.$enums.CompanyTypeId.Employee ||
      (props?.profileData?.rp_gbl_company_type_id !==
        window.$enums.CompanyTypeId.Employee &&
        +currState.occupation === window.$enums.OccupationType.Employee)
    ) {
      if (!currState?.employeeStatusId) {
        setIsSubmit(true);
        currSubmitErrors['employeeStatusId'] = 'employeeStatusId';
      }
      if (!currState?.totalExperienceId) {
        setIsSubmit(true);
        currSubmitErrors['totalExperienceId'] = 'totalExperienceId';
      }
      if (!currState?.presentExperienceId) {
        setIsSubmit(true);
        currSubmitErrors['presentExperienceId'] = 'presentExperienceId';
      }
    }
    
    if (!currState?.programDurationId) {
      setIsSubmit(true);
      currSubmitErrors['programDurationId'] = 'programDurationId';
    }

    if (Object.keys(currSubmitErrors).length !== 0) {
      return setSubmitErrors(currSubmitErrors);
    } else {
      try {
        const result = await window.$http.postWithHeaders('v2/my_profile', {
          dob: moment(
            currState?.date +
              '-' +
              (parseInt(currState?.month) + 1) +
              '-' +
              currState?.year,
            'DD-MM-YYYY'
          )
            .add(1, -'M')
            .format('YYYY-MM-DD'),
          rp_gbl_gender_id: currState?.genderId,
          rp_gbl_employee_status_id: currState?.employeeStatusId,
          rp_total_experience_id: currState?.totalExperienceId,
          rp_present_experience_id: currState?.presentExperienceId,
          rp_gbl_country_id: currState?.countryId,
          rp_gbl_occupation_id: currState?.occupation,
          rp_gbl_education_id: currState?.education,
          rp_lookup_program_duration_id: currState?.programDurationId,
        });
        if (result.code === window.$constants.STATUS.OK) {
          dispatch(save(result));
          if (result?.data?.visible_header_tabs?.includes('admin')) {
            return navigate('/manage');
          } else {
            console.log('hi2');
            return navigate(props?.navigateTo);
          }
        } else {
          window.$storageService.clearStorageData();
          console.log('Something went wrong!');
          navigate('/login/');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className={props?.value !== 'edit-profile' ? 'row mt-5' : 'row'}>
        <div className='col-12 col-xl-6 mb-4'>
          <label>First Name</label>
          <p>{currState?.firstName || ''}</p>
        </div>
        <div className='col-12 col-xl-6 mb-4'>
          <label>Last Name</label>
          <p>{currState?.lastName || ''}</p>
        </div>

        <div className='col-md-12 col-lg-6'>
          <label className='asterisk'>Date of birth</label>
          <div className='d-flex'>
            <select
              className={
                (submitErrors &&
                  submitErrors?.year &&
                  (!currState?.year || currState?.year === 'Invalid date')) ||
                (formErrors && formErrors?.year) ||
                (isSubmit && !currState?.year)
                  ? 'form-select form-control border-danger'
                  : 'form-select form-control'
              }
              id='year'
              name='year'
              onChange={handleChange}
              value={currState?.year || ''}
              format='MMM DD, YYYY'
              disabled={
                props?.profileData?.non_editable_profile_fields?.includes('dob')
                  ? true
                  : false
              }
            >
              <option hidden value=''>
                Select
              </option>
              {Years.map((el, id) => {
                return (
                  <option key={id} value={el}>
                    {el}
                  </option>
                );
              })}
            </select>
            <select
              className={
                (submitErrors &&
                  submitErrors?.month &&
                  (currState?.month === '' ||
                    currState?.month < 0 ||
                    isNaN(currState?.month))) ||
                (formErrors && formErrors?.month) ||
                (isSubmit && (currState?.month === '' || currState?.month < 0))
                  ? 'form-select form-control mx-4 border-danger'
                  : 'form-select form-control mx-4'
              }
              name='month'
              onChange={handleChange}
              value={currState?.month}
              format='MMM DD, YYYY'
              disabled={
                props?.profileData?.non_editable_profile_fields?.includes('dob')
                  ? true
                  : false
              }
            >
              <option hidden value=''>
                Select
              </option>
              {global?.months?.map((el, id) => (
                <option key={id} value={id}>
                  {el}
                </option>
              ))}
            </select>
            <select
              className={
                (submitErrors &&
                  submitErrors?.date &&
                  (!currState?.date || currState?.date === 'Invalid date')) ||
                (formErrors && formErrors?.date) ||
                (isSubmit && !currState?.date)
                  ? 'form-select form-control border-danger'
                  : 'form-select form-control'
              }
              id='day'
              name='date'
              onChange={handleChange}
              value={currState?.date || ''}
              format='MMM DD, YYYY'
              disabled={
                props?.profileData?.non_editable_profile_fields?.includes('dob')
                  ? true
                  : false
              }
            >
              <option hidden value=''>
                Select
              </option>
              {Days?.map((el, id) => {
                return (
                  <option key={id} value={el}>
                    {el}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className='col-12 col-xl-6 mb-4'>
          <label className='asterisk'>Gender</label>
          <select
            className={
              (submitErrors &&
                submitErrors?.genderId &&
                !currState?.genderId) ||
              (formErrors && formErrors?.genderId) ||
              (isSubmit && !currState?.genderId)
                ? 'form-select form-control border-danger'
                : 'form-select form-control'
            }
            aria-label='Gender'
            name='genderId'
            value={currState?.genderId || ''}
            onChange={handleChange}
            disabled={
              props?.profileData?.non_editable_profile_fields?.includes(
                'rp_gbl_gender_id'
              )
                ? true
                : false
            }
          >
            <option hidden value=''>
              Select
            </option>
            {formOptions?.gender?.map((data, id) => (
              <option value={data?.id} key={id}>
                {data?.value}
              </option>
            ))}
          </select>
        </div>

        <div className='col-12 col-xl-6 mb-4'>
          <label className='asterisk'>Country</label>
          <select
            className={
              (submitErrors &&
                submitErrors?.countryId &&
                !currState?.countryId) ||
              (formErrors && formErrors?.countryId) ||
              (isSubmit && !currState?.countryId)
                ? 'form-select form-control border-danger'
                : 'form-select form-control'
            }
            name='countryId'
            aria-label='Country'
            value={
              currState?.countryId !== undefined
                ? currState?.countryId
                : '' || ''
            }
            onChange={handleChange}
            disabled={
              props?.profileData?.non_editable_profile_fields?.includes(
                'rp_gbl_country_id'
              )
                ? true
                : false
            }
          >
            <option hidden value=''>
              Select
            </option>
            {formOptions?.country !== undefined
              ? formOptions?.country?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))
              : ''}
          </select>
        </div>
        {props?.profileData?.rp_gbl_company_type_id ? (
          <>
            {(props?.profileData?.rp_gbl_company_type_id ===
              window.$enums.CompanyTypeId.Personal ||
              props?.profileData?.rp_gbl_company_type_id ===
                window.$enums.CompanyTypeId.Clinical) && (
              <>
                <div className='col-md-12 col-lg-6 mb-4'>
                  <label className='asterisk'>Occupation</label>
                  <select
                    className={
                      (submitErrors &&
                        submitErrors?.occupation &&
                        !currState?.occupation) ||
                      (formErrors && formErrors?.occupation) ||
                      (isSubmit && !currState?.occupation)
                        ? 'form-select form-control border-danger occupation'
                        : 'form-select form-control occupation'
                    }
                    aria-label='Default select example'
                    value={currState?.occupation}
                    name='occupation'
                    onChange={handleChange}
                    disabled={
                      props?.value === 'edit-profile'
                        ? true
                        : props?.profileData?.non_editable_profile_fields?.includes(
                            'rp_gbl_occupation_id'
                          )
                        ? true
                        : false
                    }
                  >
                    <option hidden value=''>
                      Select
                    </option>
                    {formOptions?.occupation?.map((data, id) => (
                      <option value={data?.id} key={id}>
                        {data?.value}
                      </option>
                    ))}
                  </select>
                </div>
                {+currState?.occupation ===
                window.$enums.OccupationType.Student ? (
                  <>
                    <div className='col-md-12 col-lg-6 mb-4 3 box'>
                      <label className='asterisk'>Education</label>
                      <select
                        className={
                          (submitErrors &&
                            submitErrors?.education &&
                            !currState?.education) ||
                          (formErrors && formErrors?.education) ||
                          (isSubmit && !currState?.education)
                            ? 'form-select form-control border-danger'
                            : 'form-select form-control'
                        }
                        aria-label='Default select example'
                        name='education'
                        value={currState?.education}
                        onChange={handleChange}
                        disabled={
                          props?.profileData?.non_editable_profile_fields?.includes(
                            'rp_gbl_education_id'
                          )
                            ? true
                            : false
                        }
                      >
                        <option hidden value=''>
                          Select
                        </option>
                        {formOptions?.education?.map((data, id) => (
                          <option value={data?.id} key={id}>
                            {data?.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : +currState?.occupation ===
                  window.$enums.OccupationType.HouseManager ? (
                  <></>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        ) : (
          ''
        )}

        {(props?.profileData?.rp_gbl_company_type_id ===
          window.$enums.CompanyTypeId.Employee ||
          (props?.profileData?.rp_gbl_company_type_id !==
            window.$enums.CompanyTypeId.Employee &&
            +currState?.occupation ===
              window.$enums.OccupationType.Employee)) && (
          <>
            <div className='col-12 col-xl-6 mb-4'>
              <label className='asterisk'>
                How long at your present position
              </label>
              <select
                className={
                  (submitErrors &&
                    submitErrors?.presentExperienceId &&
                    !currState?.presentExperienceId) ||
                  (formErrors && formErrors?.presentExperienceId) ||
                  (isSubmit && !currState?.presentExperienceId)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                name='presentExperienceId'
                aria-label='Present Experience'
                value={currState?.presentExperienceId || ''}
                onChange={handleChange}
                disabled={
                  props?.profileData?.non_editable_profile_fields?.includes(
                    'rp_present_experience_id'
                  )
                    ? true
                    : false
                }
              >
                <option hidden value=''>
                  Select
                </option>
                {formOptions?.experience?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 col-xl-6 mb-4'>
              <label className='asterisk'>Employee Status</label>
              <select
                className={
                  (submitErrors &&
                    submitErrors?.employeeStatusId &&
                    !currState?.employeeStatusId) ||
                  (formErrors && formErrors?.employeeStatusId) ||
                  (isSubmit && !currState?.employeeStatusId)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                name='employeeStatusId'
                aria-label='Employee status'
                value={currState?.employeeStatusId || ''}
                onChange={handleChange}
                disabled={
                  props?.profileData?.non_editable_profile_fields?.includes(
                    'rp_gbl_employee_status_id'
                  )
                    ? true
                    : false
                }
              >
                <option hidden value=''>
                  Select
                </option>
                {formOptions?.employee_status?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 col-xl-6 mb-4'>
              <label className='asterisk'>Your total experience</label>
              <select
                className={
                  (submitErrors &&
                    submitErrors?.totalExperienceId &&
                    !currState?.totalExperienceId) ||
                  (formErrors && formErrors?.totalExperienceId) ||
                  (isSubmit && !currState?.totalExperienceId)
                    ? 'form-select form-control border-danger'
                    : 'form-select form-control'
                }
                name='totalExperienceId'
                aria-label='Total experience'
                value={currState?.totalExperienceId || ''}
                onChange={handleChange}
                disabled={
                  props?.profileData?.non_editable_profile_fields?.includes(
                    'rp_total_experience_id'
                  )
                    ? true
                    : false
                }
              >
                <option hidden value=''>
                  Select
                </option>
                {formOptions?.experience?.map((data, id) => (
                  <option value={data?.id} key={id}>
                    {data?.value}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </div>

      <div className='edit-profile-actions'>
        <button className='btn btn-rp-primary save' onClick={handleSubmit}>
          Save
        </button>
        {props?.value !== 'onBoarding' ? (
          <button
            className='btn btn-rp-primary-red'
            onClick={() => navigate('/view-profile')}
          >
            Cancel
          </button>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ProfileForm;
